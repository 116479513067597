import { gql } from "@apollo/client";
export const QUERY_PAYROLL_SUMMARY = gql`
  query SummaryPayroll(
    $limit: Int
    $skip: Int
    $orderBy: SummaryPayrollOrderBy
    $where: SummaryPayrollWhereInput
  ) {
    summaryPayroll(
      limit: $limit
      skip: $skip
      orderBy: $orderBy
      where: $where
    ) {
      total
      data {
        _id
        consolidatedAt
        basicSalary
        positionSalary
        livingSalary
        allowance
        extraIncome
        fuelMoney
        otIncome
        bonusIncome
        diligentIncome
        deductionExpense
        shipper_commission
        borrowExpense
        taxIncome
        InsuranceExpense
        finalIncome
        approveStatus
        approvedDate
        confirmStatus
        confirmedDate
        paidStatus
        paidDate
        forMonth
        forYear
        note
        isDrop
        bankAccountName
        bankAccountNumber
      }
    }
  }
`;

export const UPDATE = gql`
  mutation UpdateSummaryPayroll(
    $data: SummaryPayrollInput!
    $where: WhereById!
  ) {
    updateSummaryPayroll(data: $data, where: $where) {
      _id
    }
  }
`;
export const QUERY_OT = gql`
  query OtPayroll($where: OTPayrollWhereInput, $orderBy: OTPayrollOrderBy, $limit: Int) {
    otPayroll(where: $where, orderBy: $orderBy, limit: $limit) {
      data {
        _id
        note
        amountOf
      }
    }
  }
`;
export const QUERY_BONUS = gql`
  query BonusPayroll(
    $where: BonusPayrollWhereInput
    $orderBy: BonusPayrollOrderBy
  ) {
    bonusPayroll(where: $where, orderBy: $orderBy) {
      data {
        _id
        note
        amountOf
      }
    }
  }
`;

export const QUERY_DEDUCTION = gql`
  query DeductionPayroll(
    $where: DeductionPayrollWhereInput
    $orderBy: DeductionPayrollOrderBy
  ) {
    deductionPayroll(where: $where, orderBy: $orderBy) {
      data {
        _id
        note
        amountOf
      }
    }
  }
`;

export const QUERY_DEDUCTION_LOG = gql`
  query DeductionPayrollLog(
    $where: DeductionPayrollLogWhereInput
    $orderBy: DeductionPayrollLogOrderBy
    $skip: Int
    $limit: Int
  ) {
    deductionPayrollLog(
      where: $where
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      total
      data {
        _id
        amountOf
        note
      }
    }
  }
`;

export const QUERY_BORROW = gql`
  query BorrowPayrollLog(
    $where: BorrowPayrollLogWhereInput
    $orderBy: BorrowPayrollLogOrderBy
    $skip: Int
    $limit: Int
  ) {
    borrowPayrollLog(
      where: $where
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      total
      data {
        _id
        amountOf
        summaryID
        note
      }
    }
  }
`;
export const QUERY_DILiGENT = gql`
  query DiligentPayroll(
    $where: DiligentPayrollWhereInput
    $orderBy: DiligentPayrollOrderBy
  ) {
    diligentPayroll(where: $where, orderBy: $orderBy) {
      data {
        _id
        note
        amountOf
      }
    }
  }
`;

export const QUERY_EXTRA_LOG = gql`
  query ExtraIncomePayrollLog(
    $where: ExtraIncomePayrollLogWhereInput
    $orderBy: ExtraIncomePayrollLogOrderBy
    $skip: Int
    $limit: Int
  ) {
    extraIncomePayrollLog(
      where: $where
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      total
      data {
        _id
        amountOf
        note
        createdAt
      }
    }
  }
`;

export const QUERY_EXTRA = gql`
  query ExtraIncomePayroll(
    $where: ExtraIncomePayrollWhereInput
    $orderBy: ExtraIncomePayrollOrderBy
  ) {
    extraIncomePayroll(where: $where, orderBy: $orderBy) {
      data {
        note
        _id
        amountOf
        total_extra
      }
    }
  }
`;
